.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.blog-builder .block-editor-element .editor-toolbar {
  display: flex;
  justify-content: end;
}
.blog-builder .block-editor-element .editor-toolbar .toolbar-container {
  background-color: lightblue;
  padding: 0 10px;
}
.blog-builder .block-editor-element .editor-toolbar .toolbar-container .editor-tool {
  padding: 10px 0;
  cursor: pointer;
}
.blog-builder .tools-container {
  display: flex;
  border: 1px solid;
  border-radius: 5px;
  margin-bottom: 10px;
}
.blog-builder .tools-container .tool-element {
  padding: 10px;
  border: 1px solid;
  border-radius: 2px;
  margin: 10px;
  cursor: pointer;
}
.blog-builder .blog-element {
  border-radius: 5px;
}
.blog-builder .blog-img .placeholder {
  border: 1px solid #2d2d2d;
  background-color: #486290;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
}
.blog-builder .add-element {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.blog-builder .add-element button {
  background-color: unset;
  border: none;
  color: rgb(5, 212, 240);
}
.blog-builder .add-element span {
  width: 100px;
  height: 1px;
  background-color: black;
}

.blog-view .blog-element {
  margin-bottom: 10px;
}
.blog-view .blog-element-viewer .ck-widget__type-around {
  display: none;
}

