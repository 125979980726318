.m-container {
    @include mobile {
        max-width: 90%;
        margin: auto;        
    }
    @include desktop {
        max-width: 1200px;
        margin: auto;
        padding: 10px 30px;
    }
}