input, textarea {
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 5px;
    outline: none;
    width: 100%;
}

label.required::after {
    content: '*';
    color: red;
    margin-left: 3px;
}

.input-group {
    margin-bottom: 10px;
}