button, .btn {
    padding: 10px 20px;
    cursor: pointer;
    &.main {
        background-color: #1cb8ff;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
    }
    &.outline {
        background-color: unset;
        border: 1px solid #1cb8ff;
    }
    &.rounded {
        border-radius: 5px;
    }
}