.bg-light {
    background-color: #dddddd;
}
.dim-light-bg { 
	background-color: #a6b1e1; 
}
.bg-dark {
    background-color: #424874;
}
.clr-white {
    color: white;
}

.clr-reg {
    color: #2d2d2d;
}
.clr-dark {
    color: #000000;
}
.clr-err {
    color: #f36c6c;
}
.clr-light {
    color: #6b6b6b;
}