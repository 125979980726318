@import './base/margin';

.blog-builder {
    .block-editor-element {
        .editor-toolbar {
            display: flex;
            justify-content: end;
            .toolbar-container {
                background-color: lightblue;
                padding: 0 10px;
                .editor-tool {
                    padding: 10px 0;
                    cursor: pointer;
                }
            }
        }
    }
    .tools-container {
        display: flex;
        // justify-content: center;
        border: 1px solid;
        border-radius: 5px;

        .tool-element {
            // font-size: 32px;
            padding: 10px;
            border: 1px solid;
            border-radius: 2px;
            margin: 10px;
            cursor: pointer;
        }
        margin-bottom: 10px;
    }
    .blog-element {
        border-radius: 5px;
    }
    .blog-img {
        .placeholder {
            border: 1px solid #2d2d2d;
            background-color: #486290;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: white;
        }
    }
    .add-element {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        button {
            background-color: unset;
            border: none;
            color: rgb(5, 212, 240);
        }
        span{
            width: 100px;
            height: 1px;
            background-color: black;
        }
    }
}

.blog-view {
    .blog-element {
        margin-bottom: 10px;
    }
    .blog-element-viewer {
        .ck-widget__type-around {
            display: none;
        }
    }
}