@import './base/mixins';
@import './base/color';
@import './base/padding';
@import './components/input';
@import './components/flex-box';
@import './components/container';
@import './components/btn';
@import './base/shadow';
@import './base/border';
@import './base/margin';
@import './base/typography';
@import './components/img';
@import './base/position';
@import './base/display';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.mr-css {
    font-family: "Roboto", sans-serif;
}

.blog-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px 20px;
    grid-template-areas:
        "item1 item1 item1"
        "item2 item3 item4"
        "item5 item5 item5";

    .item1 {
        grid-area: item1;
    }

    .item2 {
        grid-area: item2;
        .content-item {
            flex-direction: column;
            img {

                width: 100% !important;
            }
        }
    }

    .item3 {
        grid-area: item3;
        .content-item {
            flex-direction: column;
            img {

                width: 100% !important;
            }
        }
    }

    .item4 {
        grid-area: item4;
        .content-item {
            flex-direction: column;
            img {

                width: 100% !important;
            }
        }
    }

    .item5 {
        grid-area: item5;
    }

    >:nth-child(n+5) {
        grid-column: span 3;
    }
    .blog-item {
        height: 100%;
    }
    @include mobile {
        display: block;
        .content-item {
            flex-direction: column;
            img {

                width: 100% !important;
            }
        }
    }
}

* {
    box-sizing: border-box;
}