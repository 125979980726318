.pv-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.p-10 {
    padding: 10px;
}

.ph-30 {
    padding-left: 30px;
    padding-right: 30px;
}

@include mobile {
    .ph-md-10 {
        padding-left: 10px;
        padding-right: 10px;
    }
}