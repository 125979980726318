.flex-box {
    display: flex;

    &.column {
        flex-direction: column;
    }
    &.justify {
        &.center {
            justify-content: center;
        }
        &.end {
            justify-content: end;
        }
    }

    &.between {
        justify-content: space-between;
    }

    &.align {
        &.center {
            align-items: center;
        }
    }
}