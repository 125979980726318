.border-box {
    border: 1px solid #b8b8b8;
    border-radius: 5px;
}

.rounded {
    border-radius: 5px;
}

.rounded-md {
    border-radius: 10px;
}